import Login from './userV2/login';

import userInfo from './userV2/userDetail';
import logOut from './userV2/logout';
import changePassword from './userV2/changePassword';
import updateUserInfo from './userV2/changeInfo';
import listSubAcoount from './userV2/listSubUser';
import getSubUserInfo from './userV2/getSubUserInfo';
import editSubUser from './userV2/editSubUser';
import addSubUser from './userV2/addSubUser';


//Customer
import getCustomerRecordDetail from './customerV2/getCustomerRecordDetail';
import getCustomerRecordV2 from './customerV2/getHistoryCustomer';
import getSuspiciousFace from './getSuspiciousFace/getSuspiciousFace';
import addSuspiciousFace from './getSuspiciousFace/addSuspiciousFace';
import getListCustomerV2 from './customerV2/getListCustomer';
import addCustomerV2 from './customerV2/addCustomer';
import updateCustomerV2 from './customerV2/updateCustomer';
import getListCustomerv2 from './customerV2/getListCustomer';
import deleteCustomerV2 from './customerV2/deleteCustomer';
import exportExcelHistoryCustomer from './customerV2/exportExcelHistoryCustomer';
import customerHistory from './customerV2/customerHistory.js';
import addCustomerOtherWay from './customerV2/addCustomerOtherway';
import listCustomerType from './customerV2/listCustomerType';
import addCustomerType from './customerV2/addCustomerType';
import updateCustomerType from './customerV2/updateCustomerType';
import downloadListCustomer from './customerV2/downloadListCustomer';
import deleteCustomerType from './customerV2/deleteCustomerType';
import deleteManyCustomer from './customerV2/deleteManyCustomer';
import addCustomerByExcel from './customerV2/addCustomerByExcel';
import downloadTemplateFile from './customerV2/downloadTemplateFile';

// Customer Report
import customerBehaviorAnalyst from '@/api/customerV2/customerBehaviorAnalyst';
import listCustomerByDateRange from '@/api/customerV2/listCustomerByDateRange';
import downloadListCustomerInReport from '@/api/customerV2/downloadListCustomerInReport';
import customerAgeAnalyst from '@/api/customerV2/customerAgeAnalyst';
import customerTypeAnalystAndGender from '@/api/customerV2/customerTypeAnalystAndGender';
import customerSegmentationRadar from '@/api/customerV2/customerSegmentationRadar';
//Employee v3
import getListEmployeeV3 from './attendenceV3/employee/getListEmployee';
import addEmployeeV3 from './attendenceV3/employee/addEmployee';
import deleteEmployeeV3 from './attendenceV3/employee/deleteEmployee';
import getDetailEmployeeV3 from './attendenceV3/employee/getDetailEmployee';
import editEmployeeV3 from './attendenceV3/employee/editEmployee';
import addExcelEmployeeV3 from './attendenceV3/employee/addExcelEmployee';
import getListAttendence from './attendenceV3/employee/getListAttendence';
import exportExcelListEmployee from '@/api/attendenceV3/employee/exportExcelListEmployee';


//Department v3
import addDepartmentV3 from './attendenceV3/department/addDepartment';
import getListDepartmentV3 from './attendenceV3/department/getListDepartment';
import getListDepartmentByGroup from './attendenceV3/department/getListDepartmentByGroup';
import getListGroupV2 from './group/getListGroupV2';
import updateDepartment from './attendenceV3/department/updateDepartment';

import getCustomerDetailV2 from './customerV2/getCustomerDetail';
import countingCustomerFamiliar from './customerV2/countingCustomerFamiliar';

//Heatmap
import heatMapPeopleCountingV2 from './detailDashBoardV2/heatMapPeopleCounting';
import heatmapLayout from './detailDashBoardV2/heatmapLayout';

//Route map v2
import listRouteV2 from './routeMap/listRouteV2';

//Report people counting v2
import statisticOverallV2 from './detailReportPeopleCountingV2/statisticOverall';
import greetingReport from './detailReportPeopleCountingV2/greetingReport';
import updateFirebaseToken from './userV2/updateFirebaseToken';
import checkFirebaseTokenExist from "@/api/userV2/checkFirebaseTokenExist";
import getSubscribedNotificationGroup from "@/api/userV2/getSubscribedNotificationGroup";
import totalByHours from './detailDashBoardV2/totalByHours';
import statisticDetail from './detailReportPeopleCountingV2/statisticDetail';
import statisticGateByHour from './detailReportPeopleCountingV2/statisticGateByHour';
import rankedGroup from './detailReportPeopleCountingV2/rankedGroup';
import listLayout from './layout/listLayout';
import compareOverall from './detailReportPeopleCountingV2/compareOverall.js';
import compareAllShop from './detailReportPeopleCountingV2/compareAllShop.js';
import exportDataPeopleCounting from './detailReportPeopleCountingV2/exportDataPeopleCounting';
import getListCameraModuleHeatMap from './camera/getListCameraModuleHeatMap';
import getListCameraModuleRouteMap from './camera/getListCameraModuleRouteMap';
import getReportHeatmapByCameraAndDate from './heatmap/getReportHeatmapByCameraAndDate';
import exportExcelAttendenceEmployee from './attendenceV3/employee/exportExcelAttendenceEmployee'

// dashboard shopingmall
import overviewDashboardShopingMall from './detailDashboardShopingMall/overview'
import trendingDashboardShopingMall from './detailDashboardShopingMall/trending';
import storePerformanceShopingMall from './detailDashboardShopingMall/storePerformance'
import getListZoneByGroup from '@/api/zoneAnalysis/getListZoneByGroup';
import getReportStatisticComeIn from '@/api/zoneAnalysis/getReportStatisticComeIn';

// report shopingmall
import overviewReportShopingMall from './detailReportShopingMall/overview';
import trendingReportShopingMall from './detailReportShopingMall/trending';
import getListGroupHaveGate from './group/getListGroupHaveGate';
import getListGroupHaveAnalysis from './group/getListGroupHaveAnalysis';
import getStatisticTimeInZone from '@/api/zoneAnalysis/getStatisticTimeInZone';
import gatePerformanceReportShoppingMall from './detailReportShopingMall/gatePerformance';
import getStatisticInteractionRate from '@/api/zoneAnalysis/getStatisticInteractionRate';
import heatmapReportShoppingMall from './detailReportShopingMall/heatmap';
import storePerformanceReport from './detailReportShopingMall/storePerformance';
import exportExcelShoppingmall from "./detailReportShopingMall/exportExcel"

// fully list group current user login
import getAllGroupCurrentUser from './group/getAllGroupCurrentUser';

// dashboard retail store
import overviewDashboardRetail from './detailDashBoardV2/overviewDashboardRetail'
import trendingDashboardRetail from './detailDashBoardV2/trendingDashboardRetail'
import storePerformanceRetailStore from './detailDashBoardV2/storePerformanceRetailStore'

// report retail store
import overviewReportRetail from './detailDashBoardV2/overviewReportRetail'
import storePerformanceReportRetailStore from './detailDashBoardV2/storePerformanceReportRetailStore'
import topEfficientStore from './detailDashBoardV2/topEfficientStore'
import totalGateInWeek from './detailDashBoardV2/totalGateInWeek'
import trendingReportRetail from './detailDashBoardV2/trendingReportRetail'
import rushHourRetail from './detailDashBoardV2/rushHourRetail';
import attributeReport from './detailDashBoardV2/attributeReport';

const repositories = {
    Login: Login,
    userInfo: userInfo,
    logOut: logOut,
    listSubAcoount: listSubAcoount,
    getSubUserInfo: getSubUserInfo,
    editSubUser: editSubUser,
    addSubUser: addSubUser,
    updateUserInfo: updateUserInfo,
    changePassword: changePassword,
    updateFirebaseToken: updateFirebaseToken,
    checkFirebaseTokenExist: checkFirebaseTokenExist,
    getSubscribedNotificationGroup: getSubscribedNotificationGroup,

    // Dashboard main
    totalByHours: totalByHours,

    getCustomerRecordV2: getCustomerRecordV2,
    getCustomerRecordDetail: getCustomerRecordDetail,
    getSuspiciousFace: getSuspiciousFace,
    addSuspiciousFace: addSuspiciousFace,
    getListCustomerV2: getListCustomerV2,
    addCustomerV2: addCustomerV2,
    updateCustomerV2: updateCustomerV2,
    getListCustomerv2: getListCustomerv2,
    deleteCustomerV2: deleteCustomerV2,
    deleteCustomerType: deleteCustomerType,
    getCustomerDetailV2: getCustomerDetailV2,
    addCustomerOtherWay: addCustomerOtherWay,
    countingCustomerFamiliar: countingCustomerFamiliar,
    listCustomerType: listCustomerType,
    addCustomerType: addCustomerType,
    updateCustomerType: updateCustomerType,
    downloadListCustomer: downloadListCustomer,
    deleteManyCustomer: deleteManyCustomer,
    addCustomerByExcel: addCustomerByExcel,
    downloadTemplateFile: downloadTemplateFile,

    // Customer Behavior Analyst
    customerBehaviorAnalyst: customerBehaviorAnalyst,
    listCustomerByDateRange: listCustomerByDateRange,
    downloadListCustomerInReport: downloadListCustomerInReport,
    customerAgeAnalyst: customerAgeAnalyst,
    customerTypeAnalystAndGender: customerTypeAnalystAndGender,
    customerSegmentationRadar: customerSegmentationRadar,

    //Attendancev3
    getListEmployeeV3: getListEmployeeV3,
    addEmployeeV3: addEmployeeV3,
    deleteEmployeeV3: deleteEmployeeV3,
    getDetailEmployeeV3: getDetailEmployeeV3,
    editEmployeeV3: editEmployeeV3,
    addExcelEmployeeV3: addExcelEmployeeV3,
    getListAttendence: getListAttendence,
    exportExcelAttendenceEmployee: exportExcelAttendenceEmployee,
    exportExcelListEmployee: exportExcelListEmployee,

    //Department v3
    addDepartmentV3: addDepartmentV3,
    getListDepartmentV3: getListDepartmentV3,
    getListGroupV2: getListGroupV2,
    getListDepartmentByGroup: getListDepartmentByGroup,
    updateDepartment: updateDepartment,

    // Heatmap
    heatMapPeopleCountingV2: heatMapPeopleCountingV2,
    heatmapLayout: heatmapLayout,

    // Route map
    listRouteV2: listRouteV2,

    // Report people counting v2
    statisticOverallV2: statisticOverallV2,
    greetingReport: greetingReport,
    exportExcelHistoryCustomer: exportExcelHistoryCustomer,
    customerHistory: customerHistory,
    statisticDetail: statisticDetail,
    statisticGateByHour: statisticGateByHour,
    rankedGroup: rankedGroup,
    listLayout: listLayout,
    compareOverall: compareOverall,
    compareAllShop: compareAllShop,
    exportDataPeopleCounting: exportDataPeopleCounting,
    getListCameraModuleHeatMap: getListCameraModuleHeatMap,
    getListCameraModuleRouteMap: getListCameraModuleRouteMap,
    getReportHeatmapByCameraAndDate: getReportHeatmapByCameraAndDate,

    // DashboardShopingMall
    overviewDashboardShopingMall: overviewDashboardShopingMall,
    trendingDashboardShopingMall: trendingDashboardShopingMall,
    storePerformanceShopingMall: storePerformanceShopingMall,

    getListZoneByGroup: getListZoneByGroup,
    getReportStatisticComeIn: getReportStatisticComeIn,
    getStatisticTimeInZone: getStatisticTimeInZone,
    getStatisticInteractionRate: getStatisticInteractionRate,

    // ReportShopingMall
    overviewReportShopingMall: overviewReportShopingMall,
    trendingReportShopingMall: trendingReportShopingMall,
    getListGroupHaveGate: getListGroupHaveGate,
    getListGroupHaveAnalysis: getListGroupHaveAnalysis,
    gatePerformanceReportShoppingMall: gatePerformanceReportShoppingMall,
    heatmapReportShoppingMall: heatmapReportShoppingMall,
    storePerformanceReport: storePerformanceReport,
    exportExcelShoppingmall: exportExcelShoppingmall,

    // detailDashboardRetailStore
    overviewDashboardRetail: overviewDashboardRetail,
    trendingDashboardRetail: trendingDashboardRetail,
    storePerformanceRetailStore: storePerformanceRetailStore,
    overviewReportRetail: overviewReportRetail,
    storePerformanceReportRetailStore: storePerformanceReportRetailStore,
    topEfficientStore: topEfficientStore,
    totalGateInWeek: totalGateInWeek,
    trendingReportRetail: trendingReportRetail,
    rushHourRetail: rushHourRetail,
    // fully list group current user login
    getAllGroupCurrentUser: getAllGroupCurrentUser,
    attributeReport: attributeReport
};

export const Api = {
    get: (name) => repositories[name],
};